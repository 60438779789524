<template>
	<v-dialog v-model="dialogForgotPass" persistent max-width="500px">
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12">
				<v-card class="elevation-20">
					<v-toolbar color="primary" dark flat>
						<v-toolbar-title>{{ $t('passwordReset') }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="close">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>

					<v-card-text>
						<p class="px-2">{{ $t('resetPassText') }}</p>
						<v-form ref="form" lazy-validation>
							<v-text-field
								:label="$t('email')"
								name="email"
								prepend-icon="mdi-account"
								type="email"
								v-model="email"
								:rules="emailRules"
								required
							></v-text-field>

							<!-- Success Message -->
							<v-alert v-model="isEmailSent" type="success" dense dismissible>
								{{ $t('passwordResetEmailSent') }}
							</v-alert>

							<!-- Error Messages -->
							<v-alert v-model="invalidEmail" type="error" dense dismissible>
								{{ $t('userNotFound') }}
							</v-alert>
						</v-form>

						<!-- Loader -->
						<div v-if="isLoading" class="text-center">
							<v-progress-circular
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
					</v-card-text>

					<v-card-actions class="pa-5">
						<v-btn color="primary" block @click="onSubmit">{{
							$t('submit')
						}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
export default {
	name: 'ForgotPassword',

	props: {
		dialogForgotPass: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			email: '',
			isEmailSent: false,
			invalidEmail: false,
			isLoading: false,
			emailRules: [
				v => !!v || this.$i18n.t('requiredField'),
				v => /.+@.+\..+/.test(v) || this.$i18n.t('validEmail'),
			],
		};
	},
	methods: {
		close() {
			this.$emit('close-forgot');
		},
		reset() {
			this.$refs.form.resetValidation();
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;
			const payload = {
				email: this.email,
			};

			try {
				const res = await this.$http.post(
					`${this.url}/auth/forgotpassword`,
					payload,
				);

				this.email = '';
				this.isEmailSent = true;
				this.$refs.form.resetValidation();
				this.isLoading = false;
				this.close();
			} catch (error) {
				if (
					error.response.data.error &&
					error.response.data.error.includes('No user asociated')
				) {
					this.invalidEmail = true;
				}
				console.error(error);
				this.isLoading = false;
				this.loginError = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
